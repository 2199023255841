window.onload = function () {
  const linkList = document.querySelectorAll('.categories ul li a');
  const currentURL = window.location.href;

  linkList.forEach((element) => {
    const URL = element.getAttribute('href');

    if (currentURL == URL) {
      element.classList.add('active');
    }
  });
};
